<template>
    <div class="modal-add-product">
        <div class="head flex-row flex-between items-start">
            <div
                class="title"
                v-html="$translate('ADD_PRODUCT')"/>
            <i
                @click="$emit('close')"
                class="zmdi zmdi-close"/>
        </div>
        <div class="flex-row m-t-16">
            <select v-model="selectedProductToInsert">
                <option
                    :value="product"
                    :key="product.id"
                    v-for="product in products"
                    v-html="`[${product.id}]${product.name}(${product.code})`">
                </option>
            </select>
            <button
                :disabled="!canAdd"
                @click="addProduct"
                class="btn btn-primary flex-wrap m-l-16"
                v-html="$translate('ADD')"/>
        </div>
        <div class="buttons">
            <div class="flex-row">
                <button
                    @click="$emit('close')"
                    class="btn btn-primary m-t-10">
                    {{ 'CLOSE' | translate }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
// import rubyService from '@/services/ruby'
import productService from '@/services/product'

export default {
    name: 'ModalAddProduct',
    props: ['options'],
    data: () => ({
        products: null,
        selectedProductToInsert: null,
        agents: null,
    }),
    computed: {
        canAdd() {
            let p = this.selectedProductToInsert
            if (!p) return

            if (p.id !== 68) {
                return true
            }

        },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            const productAll = await productService.all()
            const unlimited = productAll.rate_plan.filter(item => item.code.includes("unlimited"))
            this.products = [...productAll.subscription_plan, ...productAll.ticket_plan, ...productAll.package_plan, ...unlimited]

            if (this.products.length > 0) {
                this.selectedProductToInsert = this.products[0]
            }
        },
        onClickButton(btn) {
            this.$emit('close', this.buttons.indexOf(btn))
        },
        async addProduct() {
            if (!this.options.user) return

            const payload = {
                user_id: this.options.user.id,
                ptype: this.selectedProductToInsert.ptype,
                code: this.selectedProductToInsert.code,
            }
            this.$loading(true)
            try {
                const res = await productService.addProduct(payload)
                if (res.res === 'error') {
                    this.$toast.error(res.msg)                    
                } else {
                    this.$toast.success(res.msg)
                }
                
                this.$emit('close', 1)
            } catch (e) {
                // console.log(e)
                this.$toast.error(e.data)
            }
            this.$loading(false)
        },
    },
}
</script>
